.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.App-link {
  color: #61dafb;
}

.form {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}


.container { 
  text-align: center;
}



li {
  list-style: none;
}