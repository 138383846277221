.consent {
    background-color: #fcfffb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #10120e;
    border-radius: 8px;
    /*角の丸み*/
    box-shadow: 0px 0px 5px silver;
    /*5px=影の広がり具合*/
    padding: 2em 2em 2em 2em;
    margin: 2em 8em 2em 8em;
}

header {
    margin: 2em 2em 2em 2em;
}

footer {
    margin: 2em 2em 2em 2em;
}

.title {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
}